//line-banner
.line-banner{
	&__btn{
		display: flex;
		flex-wrap: wrap;
		position:fixed;
		bottom: 0;
		right: 0;
		background-color: #00C300;
		color: #fff;
		width: 175px;
		padding:10px;

		@media #{$small-only} {
			flex-wrap: nowrap;
			justify-content: space-between;
			position: fixed;
			left: 0;
			bottom: 0;
			width: 100vw;
			padding: .5rem;
			margin: 0;
		}
	}

	&__icon{
		align-self: center;
		width: 45px;
		height: 45px;
		margin-right: 5px;

		@media #{$small-only}{
			width: 13%;
			height: 13%;
			margin: 0.25rem 0;
		}
	}

	&__word{
		color: #fff;
		font-family: "ヒラギノ角ゴ Pro W6", "Hiragino Kaku Gothic ProN", sans-serif;
		font-size: 0.6rem;
		font-weight: bold;
		display: inline;
		margin: 0;

		@media #{$small-only} {
			align-self: center;
			display: flex;
			flex: 3 3 auto;
			font-size: 0.7rem;
			margin:0 0 0 .5rem;
		}
	}
}

.line-banner .line-it-button{
	margin-left: 49px;
	margin-top: -5px;
	width: 94px;

	@media #{$small-only}{
		align-self: center;
		margin: 0;
	}
}
